import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './interceptorsSlice';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import config from '@app/utils/config';

export const apiSlice = createApi({
  reducerPath: '/user',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getNavigationMenu: builder.query<any, void>({
      query: () => ({
        url: `${config.api[OperationalServiceTypes.UserService].userNavigationMenu}`,
      }),
    }),
    getUserPermissions: builder.query<any, void>({
      query: () => ({
        url: `${config.api[OperationalServiceTypes.UserService].userPermissions}`,
      }),
    }),
    getUserPreference: builder.query<any, void>({
      query: () => ({
        url: `${config.api[OperationalServiceTypes.UserService].userPreference}`,
      }),
    }),
  }),
});

export const { useLazyGetNavigationMenuQuery, useLazyGetUserPermissionsQuery, useLazyGetUserPreferenceQuery } = apiSlice;
