import AppConfig from '@app/types/AppConfig';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';

const w = window as any;

const config: AppConfig = {
  logLevel: process.env.REACT_APP_LOG_LEVEL || (w._env_ && w._env_.REACT_APP_LOG_LEVEL) || 'debug',
  discoveryURL: process.env.REACT_APP_DISCOVERY_URL || (w._env_ && w._env_.REACT_APP_DISCOVERY_URL) || 'https://discovery.dev.conarc.net',
  api: {
    [OperationalServiceTypes.UserService]: {
      userNavigationMenu: `api/users/portal-menu`,
      userPermissions: `api/permissions/logged-user-permission`,
      userPreference: `api/users/logged-user-preference`,
    },
    [OperationalServiceTypes.FileManagementService]: {
      getPublishedFilesColumns: `api/files/published-files/columns`,
      getPublishedFiles: `api/files/published-files`,
      getPublishedFilesCount: `api/files/published-files?count=true`,
      downloadFile: `api/files/published-files/{id}`,
      downloadZipFile: `api/files/published-files/zip`,     
    },
    [OperationalServiceTypes.PortalService]: {
      getSubmittedFiles: `api/files/submitted`,
      portalFilesUpload: `api/files`,
      downloadPortalZipFile: `api/files/submitted/zip`,
      downloadPortalFile: `api/files/submitted/{id}`,
      siteSearch: `api/sites`,
      softDeleteFile:`api/Files`,
    },
    [OperationalServiceTypes.FileStorageService]: {
      uploadFiles: `api/files`,
    },
    [OperationalServiceTypes.MasterDataService]: {
      themes: `api/themes`,
    },
    [OperationalServiceTypes.Discovery]: {
      getTenantContexts: `${process.env.REACT_APP_DISCOVERY_URL}api/DiscoveryContext/GetTenantContexts`,
    }
  },
  inputDebounceInterval: 500,
  dateFormat: 'YYYY-MM-DD',
  idleTime: 6000000,
  clientId: '0oa4i1277i8t0vXxm0x7',
};

const changeApi: AppConfig = ((): AppConfig => {
  Object.keys(config.api).forEach((e) => {
    Object.keys((config.api as any)[e]).forEach((v) => {
      (config.api as any)[e][v] = e + '/' + (config.api as any)[e][v];
    });
  });
  return config;
})();

export default changeApi;
